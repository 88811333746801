<template>
  <div class="check_btn">
    <div
      :class="{ isActive: showColorIndex == 0 }"
      @click="check_theScopeOf_date(0)"
    >
      Last 5 Days
    </div>
    <div
      :class="{ isActive: showColorIndex == 1 }"
      @click="check_theScopeOf_date(1)"
    >
     Last Month
    </div>
  </div>
  <div id="main"></div>
</template>

<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onBeforeMount,
  watch,
  onUnmounted,
} from 'vue'
import * as echarts from 'echarts'
import { taskStatistics } from '@/api/performance'
import handler_date from '@/utils/handler_date'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: ['date', 'userId'],
  setup(props, context) {
    let router = useRouter()
    let showColorIndex = ref(0)
    let myEchart = null
    const option = reactive({
      legend: {
        show: true,
        right: 0,
      },
      tooltip: {
        show: true,
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: [],
        axisLabel: {
          rotate: 25,
        },
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Registration',
          data: [],
          type: 'bar',
        },
        {
          name: 'Exhibition Registration',
          data: [],
          type: 'bar',
        },
      ],
    })
    function check_theScopeOf_date(b) {
      showColorIndex.value = b
    }
    // 获取表格数据
    function getTaskStatistics(dataObj = null) {
      const submitform = dataObj ? dataObj : props.date
      if (router.currentRoute.value.query.userId) {
        submitform.userId = router.currentRoute.value.query.userId
      } else {
        submitform.userId = props.userId || 0
      }
      taskStatistics(submitform).then((res) => {
        option.xAxis.data = []
        option.series[0].data = []
        option.series[1].data = []
        res.data.forEach((element) => {
          option.xAxis.data.push(element.date)
          option.series[0].data.push(element.registerCount)
          option.series[1].data.push(element.signUpExhibitionCount)
        })
        // 如果当前echarts 实例存在，销毁实例，使用新数据渲染 echarts 表
        if (myEchart) {
          myEchart.dispose()
        }
        myEchart = echarts.init(document.getElementById('main'))
        myEchart.setOption(option)
      })
    }
    onMounted(() => {
      myEchart = echarts.init(document.getElementById('main'))
      option && myEchart.setOption(option)
      window.addEventListener('resize', function () {
        myEchart.resize()
      })
    })
    onBeforeMount(() => {
      getTaskStatistics()
    })

    watch(
      () => props.date.endTime,
      (newValue, oldValue) => {
        if (newValue) {
          getTaskStatistics()
        } else {
          getTaskStatistics(handler_date())
        }
      },
      {
        deep: true,
        immediate: false,
      }
    )
    watch(
      () => showColorIndex.value,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          if (newValue == 1) {
            getTaskStatistics(handler_date(30))
          } else {
            getTaskStatistics(handler_date())
          }
        }
      },
      { immediate: false, deep: true }
    )
    watch(
      () => props.userId,
      (newValue, oldValue) => {
        getTaskStatistics()
      },
      { deep: true, immediate: false }
    )
    onUnmounted(() => {
      window.removeEventListener('resize', function () {
        myEchart.resize()
      })
    })
    return {
      showColorIndex,
      check_theScopeOf_date,
    }
  },
})
</script>

<style lang="less" scoped>
.check_btn {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  > div {
    width: 120px;
  }
}
#main {
  min-height: 400px;
}
.isActive {
  color: #4e88fe;
}
</style>
