//  处理时间，传入当前时间，返回一个对象，endTime为当前传入时间，startTime为传入时间的前五天
export default function (day = 4, time = null) {
  const timeObj = { startTime: '', endTime: '' }
  const end_time = time ? new Date(time) : new Date()
  const newData = new Date(end_time.getTime() - 1000 * 3600 * 24 * day)
  timeObj.startTime = `${newData.getFullYear()}-${
    JSON.stringify(newData.getMonth() + 1).length == 1
      ? '0' + (newData.getMonth() + 1)
      : newData.getMonth() + 1
  }-${newData.getDate()}`
  timeObj.endTime = `${end_time.getFullYear()}-${
    JSON.stringify(end_time.getMonth() + 1).length == 1
      ? '0' + (end_time.getMonth() + 1)
      : end_time.getMonth() + 1
  }-${end_time.getDate()}`
  return JSON.parse(JSON.stringify(timeObj))
}
